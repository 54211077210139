import * as THREE from 'three'
import { BoxGeometry } from 'three';
import { degToRad } from 'three/src/math/MathUtils';
import { getCenterModel, getContainerHeight, getDynamicValue, getProductDimention, getRackDepth, getShelveHeight, loadTexture, rackDepth, rackStandHeight, shelveHeight, staticWidth } from './util';

export const loadBackgroundView = (Rack) => {
    let material = [
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
    ];
    let geometry = new BoxGeometry(getDynamicValue(staticWidth, Rack.width), getContainerHeight(Rack.height, Rack.width, true, Rack), getDynamicValue(rackDepth, Rack.width));
    let mesh1 = new THREE.Mesh(geometry, material)
    let { model } = getCenterModel(mesh1)
    // scene.add(model);
    mesh1.position.set(0, 0, 0);
    return [model]
}

export const loadBaseStand = (Rack) => {
    var textureLoader = new THREE.TextureLoader();
    var map = textureLoader.load("model/background.png");
    map.repeat.set(1, 1);
    var material = [
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/shelve.png", 1, 1), transparent: true }),
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/shelve.png", 1, 1), transparent: true }),
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/shelve.png", 1, 1), transparent: true }),
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/shelve.png", 1, 1), transparent: true }),
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/shelve.png", 1, 1), transparent: true }),
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/shelve.png", 1, 1), transparent: true }),
    ];
    let standDepthPosition = ((getDynamicValue(rackDepth, Rack.width) / 2) + (getRackDepth(Rack.depth, Rack.width, Rack))) / 2
    let geometry = new BoxGeometry(getDynamicValue(staticWidth, Rack.width), getDynamicValue(rackStandHeight, Rack.width), (getDynamicValue(rackDepth, Rack.width)) + (getRackDepth(Rack.depth, Rack.width, Rack)));

    let mesh1 = new THREE.Mesh(geometry, material)
    let { model } = getCenterModel(mesh1)
    // scene.add(model);
    let standHeightPosition = -(getContainerHeight(Rack.height, Rack.width, true, Rack) / 2) + (getDynamicValue(rackStandHeight, Rack.width) / 2)
    mesh1.position.set(0, standHeightPosition, standDepthPosition);

    return [model]
}


export const loadShelveView = (Rack) => {
    let material = [
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 3, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
    ];
    let geometry = new BoxGeometry(getDynamicValue(staticWidth, Rack.width), getDynamicValue(shelveHeight, Rack.width), getRackDepth(Rack.depth, Rack.width, Rack));

    let shelfHeight = 0;
    let shelvePosition = 0;
    let productModel = []
    const shelveModels = Rack.shelves.map((shelve, i) => {
        let mesh = new THREE.Mesh(geometry, material)
        let { model } = getCenterModel(mesh)
        // scene.add(model);
        if (i > 0) {
            shelfHeight = shelfHeight + parseFloat(Rack.shelves[i - 1].shelveHeight);
            shelvePosition = shelvePosition + getDynamicValue(shelveHeight, Rack.width)
        }
        const x = 0;
        const y = getShelveHeight(shelve.shelveHeight, Rack.width, getContainerHeight(Rack.height, Rack.width, true, Rack), shelfHeight, shelvePosition, Rack) - (i + 1 === Rack?.shelves?.length ? 0.5 : 0);
        const z = (getDynamicValue(rackDepth, Rack.width) / 2) + (getRackDepth(Rack.depth, Rack.width, Rack) / 2);
        let shelfPosition = { x, y, z };
        mesh.position.set(x, y, z);
        productModel.push(...loadProducts(shelve, shelfPosition, Rack))
        return model;
    })
    return [...shelveModels, ...[].concat.apply([], productModel)]
}

export const loadProducts = (shelve, shelfPosition, Rack) => {
    let initialWidth = -(getDynamicValue(staticWidth, Rack.width) / 2);
    let prevWidth = 0;
    let productsModel = shelve.products.map((product, i) => {
        let productModel = []
        const { width, height, depth } = getProductDimention(product, Rack.width, Rack)

        var material = new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture(product.imageUrl), transparent: true });

        let geometry = new BoxGeometry(width, height, depth);
        if (i > 0) {
            initialWidth = initialWidth + prevWidth;
        }
        const { y } = shelfPosition;

        prevWidth = width;

        let initialDepth = (getDynamicValue(rackDepth, Rack.width) / 2);
        for (let j = 0; j < parseInt(getRackDepth(Rack.depth, Rack.width, Rack) / depth); j++) {
            let mesh1 = new THREE.Mesh(geometry, material)
            // scene.add(mesh1);
            if (j > 0) {
                initialDepth = initialDepth + depth;
            }
            mesh1.position.set((initialWidth) + width / 2, y + (height / 2) + getDynamicValue(shelveHeight, Rack.width) / 2, (depth / 2) + initialDepth);
            productModel.push(mesh1)
        }
        return productModel;
    })
    return productsModel;
}

export const loadRackModel = (Rack) => {
    const backgroundModel = loadBackgroundView(Rack);
    const shelvesModel = loadShelveView(Rack);
    const baseStandModel = loadBaseStand(Rack);
    return [...backgroundModel,
    ...baseStandModel,
    ...shelvesModel,
    ]
}

export const loadRackGroup = (Rack, isStore, i) => {
    const coolerModel = loadRackModel(Rack);
    const group = new THREE.Group();
    coolerModel.forEach((model) => {
        group.add(model);
    })
    if (isStore) {
        if (parseInt(Rack.rackPosition) % 2 == 1) {
            group.position.set(getDynamicValue(((parseFloat(Rack.xAxis) + (parseFloat(Rack.width) / 2)) / 2), 2), -(getDynamicValue((getContainerHeight(Rack.height, Rack.width, true, Rack) / 2), 2)), getDynamicValue(((parseFloat(Rack.yAxis) + (parseFloat(Rack.depth) / 2)) / 2), 2))
        } else {
            group.position.set(getDynamicValue(((parseFloat(Rack.xAxis) + (parseFloat(Rack.depth) / 2)) / 2), 2), -(getDynamicValue((getContainerHeight(Rack.height, Rack.width, true, Rack) / 2), 2)), getDynamicValue(((parseFloat(Rack.yAxis) + (parseFloat(Rack.width) / 2)) / 2), 2))
        }
        group.rotateY(degToRad((parseInt(Rack.rackPosition) - 1) * 270));
        getCenterModel(group);
    } else {
        group.position.set(0, 0, 0)
    }
    return group;
}
