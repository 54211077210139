import * as THREE from 'three'
import { BoxGeometry } from 'three';
import { degToRad } from 'three/src/math/MathUtils';
import { coolerDepth, coolerDepthExtension, coolerFrontDepth, coolerSideWidth, coolerStandHeight, coolerTopBaseHeight, getCenterModel, getContainerHeight, getDynamicValue, getProductDimention, getRackDepth, getShelveHeight, loadTexture, rackDepth, shelveHeight, staticWidth } from './util';

export const loadBackgroundView = (Rack) => {
    let models = []
    let material = [
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/cooler_inner.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
    ];
    let geometry = new BoxGeometry(getDynamicValue(staticWidth, Rack.width), getContainerHeight(Rack.height, Rack.width, true, Rack), getDynamicValue(coolerDepth, Rack.width));
    let mesh = new THREE.Mesh(geometry, material)
    let { model } = getCenterModel(mesh)

    models.push(model);
    mesh.position.set(0, 0, 0);



    const coolerSideDepth = getDynamicValue(coolerDepth, Rack.width) + getDynamicValue(coolerFrontDepth, Rack.width) + getRackDepth(Rack.depth, Rack.width, Rack)
    let coolerSideGeometry = new BoxGeometry(getDynamicValue(coolerSideWidth, Rack.width), getContainerHeight(Rack.height, Rack.width, true, Rack), coolerSideDepth);

    let material1 = [
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/cooler_inner.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
    ];
    let mesh1 = new THREE.Mesh(coolerSideGeometry, material1)
    let { model: model1 } = getCenterModel(mesh1)

    // scene.add(model1);
    models.push(model1)
    let coolerLeftX = -(getDynamicValue(staticWidth, Rack.width) / 2 + getDynamicValue(coolerSideWidth, Rack.width) / 2)
    let coolerLeftZ = (coolerSideDepth / 2) - (getDynamicValue(rackDepth, Rack.width)) - (getDynamicValue(rackDepth, Rack.width))
    mesh1.position.set(coolerLeftX, 0, coolerLeftZ);


    let material2 = [
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/cooler_inner.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
    ];
    let mesh2 = new THREE.Mesh(coolerSideGeometry, material2)
    let { model: model2 } = getCenterModel(mesh2)

    // scene.add(model2);
    models.push(model2)
    let coolerRightX = getDynamicValue(staticWidth, Rack.width) / 2 + getDynamicValue(coolerSideWidth, Rack.width) / 2
    let coolerRightZ = (coolerSideDepth / 2) - (getDynamicValue(rackDepth, Rack.width)) - (getDynamicValue(rackDepth, Rack.width))
    mesh2.position.set(coolerRightX, 0, coolerRightZ);

    return models;
}

export const loadBaseStand = (Rack) => {
    var material = [
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/shelve.png", 1, 1), transparent: true }),
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/shelve.png", 1, 1), transparent: true }),
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/shelve.png", 1, 1), transparent: true }),
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/shelve.png", 1, 1), transparent: true }),
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/shelve.png", 1, 1), transparent: true }),
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/shelve.png", 1, 1), transparent: true }),
    ];
    let standDepthPosition = ((getDynamicValue(coolerDepth, Rack.width) / 2) + ((getRackDepth(Rack.depth, Rack.width, Rack))) / 2) + (getDynamicValue(coolerDepthExtension, Rack.width) / 2)
    const baseStandWidth = getDynamicValue(staticWidth, Rack.width) + (getDynamicValue(coolerSideWidth, Rack.width))
    let geometry = new BoxGeometry(baseStandWidth, getDynamicValue(coolerStandHeight, Rack.width), (getDynamicValue(coolerDepth, Rack.width)) + (getRackDepth(Rack.depth, Rack.width, Rack)) + getDynamicValue(coolerDepthExtension, Rack.width));

    let mesh1 = new THREE.Mesh(geometry, material)
    let { model } = getCenterModel(mesh1)
    // scene.add(model);
    let standHeightPosition = -(getContainerHeight(Rack.height, Rack.width, true, Rack) / 2) + (getDynamicValue(coolerStandHeight, Rack.width) / 2)
    mesh1.position.set(0, standHeightPosition, standDepthPosition);

    return [model]
}

export const loadShelveView = (Rack) => {
    let material = [
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/cooler_inner.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/cooler_inner.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/cooler_inner.png", 3, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/cooler_inner.png", 3, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/cooler_inner.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/cooler_inner.png", 1, 1) }),
    ];
    let geometry = new BoxGeometry(getDynamicValue(staticWidth, Rack.width), getDynamicValue(shelveHeight, Rack.width), getRackDepth(Rack.depth, Rack.width, Rack));

    let shelfHeight = 0
    let shelvePosition = (getDynamicValue(coolerTopBaseHeight, Rack.width));
    let productModel = []
    const shelveModels = Rack.shelves.map((shelve, i) => {
        let mesh = new THREE.Mesh(geometry, material)
        let { model } = getCenterModel(mesh)
        if (i > 0) {
            shelfHeight = shelfHeight + parseFloat(Rack.shelves[i - 1].shelveHeight);
            shelvePosition = shelvePosition + getDynamicValue(shelveHeight, Rack.width)
        }
        const x = 0;
        const y = getShelveHeight(shelve.shelveHeight, Rack.width, getContainerHeight(Rack.height, Rack.width, true, Rack), shelfHeight, shelvePosition, Rack);
        const z = (getDynamicValue(coolerDepth, Rack.width) / 2) + (getRackDepth(Rack.depth, Rack.width, Rack) / 2);

        let shelfPosition = { x, y, z }
        mesh.position.set(x, y, z);
        productModel.push(...loadProducts(shelve, shelfPosition, Rack))
        return model;
    })
    return [...shelveModels, ...[].concat.apply([], productModel)]

}

export const loadProducts = (shelve, shelfPosition, Rack) => {
    let initialWidth = -(getDynamicValue(staticWidth, Rack.width) / 2);
    let prevWidth = 0;
    let productsModel = shelve.products.map((product, i) => {
        let productModel = []
        const { width, height, depth } = getProductDimention(product, Rack.width, Rack)

        var material = new THREE.MeshBasicMaterial({
            wireframe: false, map: loadTexture(
                product.imageUrl
            ), transparent: true
        });
        let geometry = new BoxGeometry(width, height, depth);
        if (i > 0) {
            initialWidth = initialWidth + prevWidth;
        }
        const { y } = shelfPosition;

        prevWidth = width;

        let initialDepth = (getDynamicValue(coolerDepth, Rack.width) / 2);
        for (let j = 0; j < parseInt(getRackDepth(Rack.depth, Rack.width, Rack) / depth); j++) {
            let mesh1 = new THREE.Mesh(geometry, material)
            // scene.add(mesh1);
            if (j > 0) {
                initialDepth = initialDepth + depth;
            }
            mesh1.position.set((initialWidth) + width / 2, y + (height / 2) + getDynamicValue(shelveHeight, Rack.width) / 2, (depth / 2) + initialDepth);
            productModel.push(mesh1)
        }
        return productModel;
    })
    return productsModel;
}

export const loadTopBase = (Rack) => {
    var material = [
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/shelve.png", 1, 1), transparent: true }),
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/shelve.png", 1, 1), transparent: true }),
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/shelve.png", 1, 1), transparent: true }),
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/cooler_inner.png", 1, 1), transparent: true }),
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/shelve.png", 1, 1), transparent: true }),
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/shelve.png", 1, 1), transparent: true }),
    ];
    let standDepthPosition = ((getDynamicValue(coolerDepth, Rack.width) / 2) + ((getRackDepth(Rack.depth, Rack.width, Rack))) / 2) + getDynamicValue(coolerDepthExtension, Rack.width) / 2
    let geometry = new BoxGeometry(getDynamicValue(staticWidth, Rack.width), getDynamicValue(coolerTopBaseHeight, Rack.width), (getDynamicValue(coolerDepth, Rack.width)) + (getRackDepth(Rack.depth, Rack.width, Rack)) + getDynamicValue(coolerDepthExtension, Rack.width));

    let mesh1 = new THREE.Mesh(geometry, material)
    let { model } = getCenterModel(mesh1)
    // scene.add(model);
    let standHeightPosition = -(-(getContainerHeight(Rack.height, Rack.width, true, Rack) / 2) + (getDynamicValue(coolerTopBaseHeight, Rack.width) / 2))
    mesh1.position.set(0, standHeightPosition, standDepthPosition);
    return [model]
}

export const loadDoorWithHandle = (Rack) => {
    const coolerSideDepth = getDynamicValue(coolerDepth, Rack.width) + getDynamicValue(coolerFrontDepth, Rack.width) + getRackDepth(Rack.depth, Rack.width, Rack)
    let coolerSideGeometry = new BoxGeometry(getDynamicValue(coolerSideWidth, Rack.width), getContainerHeight(Rack.height, Rack.width, true, Rack), getDynamicValue(coolerDepthExtension, Rack.width));
    let material = [
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 2, 1) }),
        // new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
        // new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 2, 1) }),
        // new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
    ];
    let mesh = new THREE.Mesh(coolerSideGeometry, material)
    let { model } = getCenterModel(mesh)

    let coolerRightZ = ((coolerSideDepth / 2) - (getDynamicValue(rackDepth, Rack.width) * 2)) * 2 + (getDynamicValue(coolerDepthExtension, Rack.width) * 2)
    mesh.position.set(0, 0, coolerRightZ);

    // let coolerFrontDoorGeometry = new BoxGeometry(getDynamicValue(staticWidth, Rack.width), getContainerHeight(Rack.height, Rack.width, true, Rack), getDynamicValue(coolerDepthExtension, Rack.width));
    // let coolerFrontDoorMaterial = [
    //     new THREE.MeshBasicMaterial({ wireframe: false, transparent: true }),
    //     new THREE.MeshBasicMaterial({ wireframe: false, transparent: true }),
    //     new THREE.MeshBasicMaterial({ wireframe: false, transparent: true }),
    //     new THREE.MeshBasicMaterial({ wireframe: false, transparent: true }),
    //     new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, color: new THREE.Color('#808080'), opacity: 0.2 }),
    //     new THREE.MeshBasicMaterial({ wireframe: false, transparent: true }),
    // ];
    // let coolerFrontDoorMesh = new THREE.Mesh(coolerFrontDoorGeometry, coolerFrontDoorMaterial)
    // let { model: coolerFrontDoorModel } = getCenterModel(coolerFrontDoorMesh)

    // scene.add(coolerFrontDoorModel);
    // let coolerFrontDoorRightX = ((coolerSideDepth / 2) - (getDynamicValue(rackDepth, Rack.width) * 2)) * 2 + (getDynamicValue(coolerDepthExtension, Rack.width))
    // coolerFrontDoorMesh.position.set(0, 0, coolerFrontDoorRightX);

    return [model]
}

export const loadCoolerModel = (Rack) => {
    const backgroundModel = loadBackgroundView(Rack);
    const baseStandModel = loadBaseStand(Rack);
    const topBaseModel = loadTopBase(Rack)
    // const doorHandleModel = loadDoorWithHandle(Rack)
    const shelvesModel = loadShelveView(Rack);
    return [...backgroundModel,
    ...baseStandModel,
    ...topBaseModel,
    ...shelvesModel,
    // ...doorHandleModel,
    ]
}

export const loadCoolerGroup = (Rack, isStore, i) => {
    const coolerModel = loadCoolerModel(Rack);
    const group = new THREE.Group();
    coolerModel.forEach((model) => {
        group.add(model);
    })
    if (isStore) {
        if (parseInt(Rack.rackPosition) % 2 == 1) {
            group.position.set(getDynamicValue(((parseFloat(Rack.xAxis) + (parseFloat(Rack.width) / 2)) / 2), 2), -(getDynamicValue((getContainerHeight(Rack.height, Rack.width, true, Rack) / 2), 2)), getDynamicValue(((parseFloat(Rack.yAxis) + (parseFloat(Rack.depth) / 2)) / 2), 2))
        } else {
            group.position.set(getDynamicValue(((parseFloat(Rack.xAxis) + (parseFloat(Rack.depth) / 2)) / 2), 2), -(getDynamicValue((getContainerHeight(Rack.height, Rack.width, true, Rack) / 2), 2)), getDynamicValue(((parseFloat(Rack.yAxis) + (parseFloat(Rack.width) / 2)) / 2), 2))
        }
        group.rotateY(degToRad((parseInt(Rack.rackPosition) - 1) * 270));
        getCenterModel(group);
    } else {
        group.position.set(0, 0, 0)
    }
    return group;
}