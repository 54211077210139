import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PlanogramStoreView from '../planogram_page/PlanogramStore';

class PlanogramStore extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            Store: undefined
        }
    }

    componentDidMount() {
        window.addEventListener('message', event => {
            // IMPORTANT: check the origin of the data! 
            console.log('event', event.data.message)
            this.initiateRendering(event.data.message)
        });
    }
    initiateRendering = (Store) => {
        this.setState({ Store })
    }
    render() {
        const { Store } = this.state;
        return (<div id="rack-canvas">
            {Store && <PlanogramStoreView Store={Store} /> }
        </div>);
    }
}



const mapStateToProps = (state) => {
    return {}
}

export default withRouter(connect(mapStateToProps, {})(PlanogramStore))