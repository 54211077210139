import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Planogram from '../planogram_page/Planogram';
import PlanogramFruits from '../planogram_page/PlanogramFruits';
import PlanogramCooler from './PlanogramCooler';

class PlanogramMain extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            Rack: undefined
        }
    }

    componentDidMount() {
        window.addEventListener('message', event => {
            // IMPORTANT: check the origin of the data! 
            console.log('event', event.data.message)
            this.initiateRendering(event.data.message)
        });
    }
    initiateRendering = (Rack) => {
        this.setState({ Rack })
    }

    renderPlanogramComponent = (Rack) => {
        if(Rack) {
            switch(Rack.type) {
                case 1:
                    return <Planogram Rack={Rack} />;
                case 2:
                    return <PlanogramCooler Rack={Rack} />;
                case 3:
                    return <PlanogramFruits Rack={Rack} />;
                default:
                    return null;
            }
        }
        return null;
    }

    render() {
        const { Rack } = this.state;
        return (<div id="rack-canvas">
            {this.renderPlanogramComponent(Rack)}
        </div>);
    }
}



const mapStateToProps = (state) => {
    return {}
}

export default withRouter(connect(mapStateToProps, {})(PlanogramMain))