import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Planogram from '../planogram_page/PlanogramCooler';
// import Rack from '../../config/cooler.json'

class PlanogramCooler extends React.Component {
    render() {
        return (
            <div id="rack-canvas">
                <Planogram Rack={this.props.Rack} />
            </div>
        );
    }
}



const mapStateToProps = (state) => {
    return { }
}

export default withRouter(connect(mapStateToProps, {})(PlanogramCooler))