import * as THREE from 'three'

export const staticWidth = 1;

export const getDynamicValue = (value, width = 1) => {
    return (value * width) / 2;
};

export const shelveHeight = 0.016;
export const shelveFrontHeight = 0.048;
export const shelveFrontDepth = 0.032;
export const shelveLeftWidth = 0.0016;

export const rackDepth = 0.02;
export const rackStandHeight = 0.1;

export const fruitRackDepth = 0.05;
export const fruitRackStandHeight = 0.2;
export const fruitRackTilt = 0.1
export const tiltInitialPosition = (height, isInitial) => (((fruitRackTilt) * height) - (isInitial ? 0 : 3))

export const coolerStandHeight = 0.3;
export const coolerTopBaseHeight = 0.05;
export const coolerDepth = 0.1;
export const coolerSideWidth = 0.05;
export const coolerFrontDepth = 0.05;
export const coolerDepthExtension = 0.01;

export const getContainerHeight = (height, width, isOverall, Rack) => {
    const percentWidth = getDynamicValue(staticWidth, Rack.width) / 100;
    const heightPercent = height / (width / 100);
    let heightValue = (percentWidth * heightPercent);
    if (isOverall) {
        const { shelves } = Rack;
        heightValue = (percentWidth * heightPercent) + ((shelves.length * getDynamicValue(shelveHeight, Rack.width))) + getStandHeight(Rack);
    }
    return heightValue;
}

const getStandHeight = (Rack) => {
    switch(Rack.type) {
        case 1:
            return getDynamicValue(rackStandHeight, Rack.width);
        case 2:
            return getDynamicValue(coolerStandHeight, Rack.width) + getDynamicValue(coolerTopBaseHeight, Rack.width);
        case 3:
            return getDynamicValue(fruitRackStandHeight, Rack.width);
        default:
            return 0;
    }
}

export const getShelveHeight = (height, width, overallHeight, shelfHeight, shelvePosition, Rack) => {
    const percentWidth = getDynamicValue(staticWidth, Rack.width) / 100;
    const heightPercent = (parseFloat(height) + parseFloat(shelfHeight)) / (width / 100);
    let heightValue = (percentWidth * heightPercent);
    const rackHeight = overallHeight / 2;
    return rackHeight - heightValue - shelvePosition;
}

export const getRackDepth = (depth, width, Rack) => {
    const percentWidth = getDynamicValue(staticWidth, Rack.width) / 100;
    const depthPercent = depth / (width / 100);
    let depthValue = (percentWidth * depthPercent);
    return depthValue;
}

export const getProductDimention = (product, widthValue, Rack) => {
    const percentWidth = getDynamicValue(staticWidth, Rack.width) / 100;
    const widthPercent = parseFloat(product.widthInCm ? product.widthInCm : parseInt(product?.bay?.bayWidth) ) / (widthValue / 100);
    const heightPercent = parseFloat(product.heightInCm ? product.heightInCm : 0 ) / (widthValue / 100);
    const depthPercent = parseFloat(product.depthInCm ? product.depthInCm : 0) / (widthValue / 100);
    const width = (percentWidth * widthPercent);
    const height = (percentWidth * heightPercent);
    const depth = (percentWidth * depthPercent);
    return { width, height, depth };
}

export const loadTexture = (url, x = 1, y = 1) => {
    var texture = new THREE.TextureLoader();
    texture.setRequestHeader({ "Access-Control-Allow-Origin": '*' })
    let textureLoader = texture.load(url);
    textureLoader.wrapS = THREE.RepeatWrapping;
    textureLoader.wrapT = THREE.RepeatWrapping;
    textureLoader.repeat.set(x, y);
    return textureLoader;
}

export const getCenterModel = (model) => {
    var box = new THREE.Box3().setFromObject(model);
    box.getCenter(model.position); // this re-sets the mesh position
    model.position.multiplyScalar(- 1);
    let measure = new THREE.Vector3();
    box.getSize(measure)
    return { model, measure }
}