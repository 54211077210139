
const NAME = "STORE";
export const UPDATE_RACK_DETAIL = `${NAME}/UPDATE_RACK_DETAIL`;


export const getStoreDetail = store => store[NAME]
export const updateRackDetail = (data) => {
    return {
        type: UPDATE_RACK_DETAIL,
        data
    }
}
