import * as THREE from 'three'
import { BoxGeometry } from 'three';
import { degToRad } from 'three/src/math/MathUtils';
import { fruitRackDepth, fruitRackStandHeight, fruitRackTilt, getCenterModel, getContainerHeight, getDynamicValue, getProductDimention, getRackDepth, getShelveHeight, loadTexture, shelveFrontDepth, shelveFrontHeight, shelveHeight, shelveLeftWidth, staticWidth, tiltInitialPosition } from './util';

export const loadBackgroundView = (Rack) => {
    let material = [
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
    ];
    let geometry = new BoxGeometry(getDynamicValue(staticWidth, Rack.width), getContainerHeight(Rack.height, Rack.width, true, Rack), getDynamicValue(fruitRackDepth, Rack.width));
    let mesh1 = new THREE.Mesh(geometry, material)
    let { model } = getCenterModel(mesh1)
    // scene.add(model);
    mesh1.position.set(0, 0, 0);
    return [model]
}

export const loadBaseStand = (Rack, shelfDepth) => {
    var textureLoader = new THREE.TextureLoader();
    var map = textureLoader.load("model/background.png");
    map.repeat.set(1, 1);
    var material = [
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/shelve.png", 1, 1), transparent: true }),
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/shelve.png", 1, 1), transparent: true }),
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/shelve.png", 1, 1), transparent: true }),
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/shelve.png", 1, 1), transparent: true }),
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/shelve.png", 1, 1), transparent: true }),
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/shelve.png", 1, 1), transparent: true }),
    ];
    let standDepthPosition = ((getDynamicValue(fruitRackDepth, Rack.width) / 2) + (getRackDepth(shelfDepth, Rack.width, Rack))) / 2
    let geometry = new BoxGeometry(getDynamicValue(staticWidth, Rack.width), getDynamicValue(fruitRackStandHeight, Rack.width), (getDynamicValue(fruitRackDepth, Rack.width)) + (getRackDepth(shelfDepth, Rack.width, Rack)));

    let mesh1 = new THREE.Mesh(geometry, material)
    let { model } = getCenterModel(mesh1)
    // scene.add(model);
    let standHeightPosition = -(getContainerHeight(Rack.height, Rack.width, true, Rack) / 2) + (getDynamicValue(fruitRackStandHeight, Rack.width) / 2)
    mesh1.position.set(0, standHeightPosition, standDepthPosition);

    return model
}


export const loadShelveView = (Rack) => {
    let material = [
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 3, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
        new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/shelve.png", 1, 1) }),
    ];

    let shelfHeight = 0;
    let shelfDepth = 0;
    let shelvePosition = 0;
    let productModel = []
    let initialDepth = 0
    let tiltPosition = (tiltInitialPosition(Rack.height, true) * 2) / Rack.shelves.length;
    const shelveModels = Rack.shelves.map((shelve, i) => {
        if (i > 0) {
            shelfHeight = shelfHeight + parseFloat(Rack.shelves[i - 1].shelveHeight);
            shelvePosition = shelvePosition + getDynamicValue(shelveHeight, Rack.width)
            // shelfDepth = shelfDepth + (parseFloat(Rack.depth)/2)
            shelfDepth = shelfDepth;
        } else {
            shelfDepth = shelfDepth + (parseFloat(Rack.depth))
            initialDepth = (parseFloat(Rack.depth))
        }
        let geometry = new BoxGeometry(getDynamicValue(staticWidth, Rack.width), getDynamicValue(shelveHeight, Rack.width), getRackDepth(shelfDepth, Rack.width, Rack));
        let mesh = new THREE.Mesh(geometry, material)
        let { model } = getCenterModel(mesh)

        let shelfFrontFace = new BoxGeometry(getDynamicValue(staticWidth, Rack.width), getDynamicValue(shelveFrontHeight, Rack.width), getRackDepth(shelveFrontDepth, Rack.width, Rack));
        let shelfFrontFaceMesh = new THREE.Mesh(shelfFrontFace, material)
        let { model: shelfFrontFaceModel } = getCenterModel(shelfFrontFaceMesh)

        let shelfLeftFace = new BoxGeometry(getDynamicValue(shelveLeftWidth, Rack.width), getDynamicValue(shelveFrontHeight, Rack.width), getRackDepth(shelfDepth, Rack.width, Rack));
        let shelfLeftFaceMesh = new THREE.Mesh(shelfLeftFace, material)
        let { model: shelfLeftFaceModel } = getCenterModel(shelfLeftFaceMesh)

        let shelfRightFace = new BoxGeometry(getDynamicValue(shelveLeftWidth, Rack.width), getDynamicValue(shelveFrontHeight, Rack.width), getRackDepth(shelfDepth, Rack.width, Rack));
        let shelfRightFaceMesh = new THREE.Mesh(shelfRightFace, material)
        let { model: shelfRightFaceModel } = getCenterModel(shelfRightFaceMesh)

        const inclinedValue = (-tiltInitialPosition(Rack.height)) + ((i * 0.80) * tiltPosition) + (Rack.height / 75)
        const x = 0;
        const y = getShelveHeight(shelve.shelveHeight, Rack.width, getContainerHeight(Rack.height, Rack.width, true, Rack), shelfHeight, shelvePosition, Rack);
        const z = (getDynamicValue(fruitRackDepth, Rack.width) / 2) + (getRackDepth(shelfDepth, Rack.width, Rack) / 2) + inclinedValue

        const x2 = 0;
        const y2 = getShelveHeight(shelve.shelveHeight, Rack.width, getContainerHeight(Rack.height, Rack.width, true, Rack), shelfHeight, shelvePosition, Rack) + (getDynamicValue(shelveFrontHeight, Rack.width) / 2);
        const z2 = (getDynamicValue(fruitRackDepth, Rack.width) / 2) + (getRackDepth(shelfDepth, Rack.width, Rack)) + inclinedValue

        const x3 = -(getDynamicValue(staticWidth, Rack.width) / 2);
        const y3 = getShelveHeight(shelve.shelveHeight, Rack.width, getContainerHeight(Rack.height, Rack.width, true, Rack), shelfHeight, shelvePosition, Rack) + (getDynamicValue(shelveFrontHeight, Rack.width) / 2);
        const z3 = (getDynamicValue(fruitRackDepth, Rack.width) / 2) + (getRackDepth(shelfDepth, Rack.width, Rack) / 2) + inclinedValue

        const x4 = (getDynamicValue(staticWidth, Rack.width) / 2) + getDynamicValue(shelveLeftWidth, Rack.width);
        const y4 = getShelveHeight(shelve.shelveHeight, Rack.width, getContainerHeight(Rack.height, Rack.width, true, Rack), shelfHeight, shelvePosition, Rack) + (getDynamicValue(shelveFrontHeight, Rack.width) / 2);
        const z4 = (getDynamicValue(fruitRackDepth, Rack.width) / 2) + (getRackDepth(shelfDepth, Rack.width, Rack) / 2) + inclinedValue


        let shelfPosition = { x, y, z };
        model.position.set(x, y, z);
        shelfFrontFaceModel.position.set(x2, y2, z2);
        shelfLeftFaceModel.position.set(x3, y3, z3);
        shelfRightFaceModel.position.set(x4, y4, z4);
        const productList = loadProducts(shelve, shelfPosition, Rack, shelfDepth, i, (-tiltInitialPosition(Rack.height)) + ((i * 0.80)  * tiltPosition) + (Rack.height / 75));
        const group = new THREE.Group();
        productList.forEach((products) => {
            products.forEach((model) => {
                group.add(model);
            })
        })

        group.add(model)
        group.add(shelfFrontFaceModel)
        group.add(shelfLeftFaceModel)
        group.add(shelfRightFaceModel)
        group.rotateX(Math.PI * fruitRackTilt)
        return group;
    })
    return shelveModels
}

export const loadProducts = (shelve, shelfPosition, Rack, shelfDepth, index, tiltSpace) => {
    let initialWidth = -(getDynamicValue(staticWidth, Rack.width) / 2);
    let prevWidth = 0;
    let totalWidth = 0;
    let noOfCloumnsPerProduct = 0;

   
    let productsModel = shelve?.products?.map((product, i) => {
        let productModel = []
        const { width, height, depth } = getProductDimention(product, Rack.width, Rack)
        
        var material = new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture(product?.imageUrl), transparent: true });
        
        let geometry = new BoxGeometry(width, height, depth);
        if (i > 0) {
            initialWidth = initialWidth + prevWidth;
        }
        const { y: yVal } = shelfPosition;
      
        if(Rack.type === 3) {
            prevWidth = getRackDepth(parseFloat(product.bay?.bayWidth), Rack.width, Rack);
            // prevWidth = width;
            console.log(width, product.bay?.bayWidth)
            let numberOfRowsInBay =  parseInt(parseFloat(product.bay?.bayWidth) / product.widthInCm)
            console.log(numberOfRowsInBay)
            let prodWidth = 0;
            let prevProductWidth = 0;
            for (let k=0; k < numberOfRowsInBay; k++) {
                if(k > 0) {
                    prodWidth = prodWidth + prevProductWidth
                }
                prevProductWidth = width;
                let initialDepth = (getDynamicValue(fruitRackDepth, Rack.width) / 2) + ((index > 0 ? (shelfDepth - (Rack.depth) ) : 0) / 2);
                for (let j = 0; j < parseInt(getRackDepth(Rack.depth, Rack.width, Rack) / depth); j++) {
                    let mesh1 = new THREE.Mesh(geometry, material)
                    // scene.add(mesh1);
                    if (j > 0) {
                        initialDepth = initialDepth + depth;
                    }
                    const x = (initialWidth) + (width / 2) + (prodWidth)
                    const y = (yVal + (height / 2) +( getDynamicValue(shelveHeight, Rack.width) / 2)) 
                    const z = (depth / 2) + initialDepth + tiltSpace
                    mesh1.position.set(x, y, z);
                    productModel.push(mesh1)
                }
            }
            
        } else {
            prevWidth = width;
            let initialDepth = (getDynamicValue(fruitRackDepth, Rack.width) / 2) + ((index > 0 ? (shelfDepth - (Rack.depth) ) : 0) / 2);
            for (let j = 0; j < parseInt(getRackDepth(Rack.depth, Rack.width, Rack) / depth); j++) {
                let mesh1 = new THREE.Mesh(geometry, material)
                // scene.add(mesh1);
                if (j > 0) {
                    initialDepth = initialDepth + depth;
                }
                const x = (initialWidth) + width / 2
                const y = (yVal + (height / 2) +( getDynamicValue(shelveHeight, Rack.width) / 2)) 
                const z = (depth / 2) + initialDepth + tiltSpace
                mesh1.position.set(x, y, z);
                productModel.push(mesh1)
            }
        }
        
        return productModel;
    })
    return productsModel;
}

export const loadFruitRackModel = (Rack) => {
    const backgroundModel = loadBackgroundView(Rack);
    const shelvesModel = loadShelveView(Rack);
    // const baseStandModel = loadBaseStand(Rack);
    return [...backgroundModel,
    // ...baseStandModel,
    ...shelvesModel,
    ]
}

export const loadFruitRackGroup = (Rack, isStore, i) => {
    const coolerModel = loadFruitRackModel(Rack);
    const group = new THREE.Group();
    coolerModel.forEach((model) => {
        group.add(model);
    })
    if (isStore) {
        if (parseInt(Rack.rackPosition) % 2 == 1) {
            group.position.set(getDynamicValue(((parseFloat(Rack.xAxis) + (parseFloat(Rack.width) / 2)) / 2), 2), -(getDynamicValue((getContainerHeight(Rack.height, Rack.width, true, Rack) / 2), 2)), getDynamicValue(((parseFloat(Rack.yAxis) + (parseFloat(Rack.depth) / 2)) / 2), 2))
        } else {
            group.position.set(getDynamicValue(((parseFloat(Rack.xAxis) + (parseFloat(Rack.depth) / 2)) / 2), 2), -(getDynamicValue((getContainerHeight(Rack.height, Rack.width, true, Rack) / 2), 2)), getDynamicValue(((parseFloat(Rack.yAxis) + (parseFloat(Rack.width) / 2)) / 2), 2))
        }
        group.rotateY(degToRad((parseInt(Rack.rackPosition) - 1) * 270));
        getCenterModel(group);
    } else {
        group.position.set(0, 0, 0)
    }
    return group;
}
