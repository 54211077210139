
import React from 'react';
import { Route, Switch } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./asset/css/style.css";
import "./asset/css/custom.css";
import "./asset/css/3d.css";
// import PlanogramCooler from './container/Planogram/PlanogramCooler';
import PlanogramFrame from './container/Planogram/PlanogramFrame';
import PlanogramStore from './container/Planogram/PlanogramStore';
import { Home } from './container/Home';
import { Store } from './container/Home/Store';


function App() {
  return (
    <Switch>
      <Route path={'/'} exact component={Home}></Route>
      {/* <Route path={'/cooler'} exact component={PlanogramCooler} ></Route> */}
      <Route path={'/frame'} exact component={PlanogramFrame}></Route>
      <Route path={'/st'} exact component={PlanogramStore}></Route>
      <Route path={'/store'} exact component={PlanogramStore}></Route>
    </Switch>
  );
}

export default App;
