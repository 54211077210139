import React from 'react';
import store from '../../config/store.json'


export class Store extends React.Component {

    componentDidMount() {
        setTimeout(() => {
            document.getElementById('iframe_racks').contentWindow.postMessage({message: store}, "*")
        }, 2000)
    }
    render() {
        return (<div className='rack-canvas'>
            <iframe id="iframe_racks" src="/store" style={{width: '100%', height: '100%', boxShadow: 'rgb(0 0 0 / 16%) 0px 1px 4px'}}></iframe>
        </div>)
    }
}