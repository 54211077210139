import * as THREE from 'three'
import { BoxGeometry } from 'three';
import { getCenterModel, getDynamicValue, loadTexture, } from './util';

export const loadStoreBase = (Store) => {
    var textureLoader = new THREE.TextureLoader();
    var map = textureLoader.load("model/background.png");
    map.repeat.set(1, 1);
    var material = [
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/cooler_inner.png", 1, 1), transparent: true }),
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/cooler_inner.png", 1, 1), transparent: true }),
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/cooler_inner.png", 1, 1), transparent: true }),
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/cooler_inner.png", 1, 1), transparent: true }),
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/cooler_inner.png", 1, 1), transparent: true }),
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/cooler_inner.png", 1, 1), transparent: true }),
    ];
    const xp = getDynamicValue(((parseFloat(650)) / 2), 2)
    const zp = getDynamicValue(((parseFloat(Store.storeLength) * 100 / Store.storeWidth) * 6.5 / 2), 2)
    const yp = getDynamicValue(((parseFloat(Store.storeHeight) * 100 / Store.storeWidth) * 6.5 / 2), 2)
    let geometry = new BoxGeometry(xp, 0, zp);

    let mesh1 = new THREE.Mesh(geometry, material)
    let { model } = getCenterModel(mesh1)
    mesh1.position.set(0, -(yp / 2), 0);

    return model
}

export const loadSideBase = (Store) => {
    var textureLoader = new THREE.TextureLoader();
    var map = textureLoader.load("model/background.png");
    map.repeat.set(1, 1);
    var material = [
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/cooler_inner.png", 1, 1), transparent: true }),
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/cooler_inner.png", 1, 1), transparent: true }),
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/cooler_inner.png", 1, 1), transparent: true }),
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/cooler_inner.png", 1, 1), transparent: true }),
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/cooler_inner.png", 1, 1), transparent: true }),
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/cooler_inner.png", 1, 1), transparent: true }),
    ];
    const xp = getDynamicValue(((parseFloat(650)) / 2), 1)
    const zp = getDynamicValue(((parseFloat(Store.storeLength) * 100 / Store.storeWidth) * 6.5 / 2), 2)
    const yp = getDynamicValue(((parseFloat(Store.storeHeight) * 100 / Store.storeWidth) * 6.5 / 2), 2)
    let geometry = new BoxGeometry(1, yp, zp);

    let mesh = new THREE.Mesh(geometry, material)
    let { model } = getCenterModel(mesh)
    
    mesh.position.set(xp, 0, 0);
    let mesh1 = new THREE.Mesh(geometry, material)
    let { model: model1 } = getCenterModel(mesh1)
    mesh1.position.set(-xp, 0, 0);

    return [model, model1]
}

export const loadTopBase = (Store) => {
    var textureLoader = new THREE.TextureLoader();
    var map = textureLoader.load("model/background.png");
    map.repeat.set(1, 1);
    var material = [
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/cooler_inner.png", 1, 1), transparent: true }),
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/cooler_inner.png", 1, 1), transparent: true }),
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/cooler_inner.png", 1, 1), transparent: true }),
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/cooler_inner.png", 1, 1), transparent: true }),
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/cooler_inner.png", 1, 1), transparent: true }),
        new THREE.MeshBasicMaterial({ wireframe: false, map: loadTexture("model/cooler_inner.png", 1, 1), transparent: true }),
    ];
    const xp = getDynamicValue(((parseFloat(650)) / 2), 2)
    const zp = getDynamicValue(((parseFloat(Store.storeLength) * 100 / Store.storeWidth) * 6.5 / 2), 1)
    const yp = getDynamicValue(((parseFloat(Store.storeHeight) * 100 / Store.storeWidth) * 6.5 / 2), 2)
    let geometry = new BoxGeometry(xp, yp, 1);

    let mesh = new THREE.Mesh(geometry, material)
    let { model } = getCenterModel(mesh)
    
    mesh.position.set(0, 0, zp);
    // let mesh1 = new THREE.Mesh(geometry, material)
    // let { model: model1 } = getCenterModel(mesh1)
    // mesh1.position.set(0, 0, -getDynamicValue(parseFloat(Store.storeLength) * 100, 0.75));

    // return [model, model1]
    return [model]
}

export const loadStoreView = (Store) => {
    const storeGroup = new THREE.Group();
    storeGroup.add(loadStoreBase(Store))
    loadSideBase(Store).forEach((model) => {
        storeGroup.add(model)
    })
    loadTopBase(Store).forEach((model) => {
        storeGroup.add(model)
    })
    return storeGroup;
}
