import React from 'react'
import { withRouter } from 'react-router-dom';
import * as THREE from 'three'
import { BoxGeometry } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { degToRad } from 'three/src/math/MathUtils';

import { loadCoolerGroup } from '../../util/cooler';
import { loadRackGroup } from '../../util/rack';
import { loadStoreView } from '../../util/store';
import { getCenterModel, getDynamicValue, loadTexture } from '../../util/util';
import { loadStoreCornerGroup } from '../../util/store_view';
import { loadFruitRackGroup } from '../../util/fruits';

class PlanogramStore extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        if (this.props.Store) {
            this.render3DModel()
        }
    }

    componentDidUpdate(nextProps) {
        if (this.props.Store !== nextProps.Store) {
            this.render3DModel()
        }
    }

    render3DModel = () => {
        this.renderStoreViewModel()
    }

    renderStoreViewModel = () => {
        const { Store } = this.props;

        const element = document.getElementById('rack-canvas')
        const scene = new THREE.Scene()
        const light = new THREE.PointLight()
        light.position.set(2.5, 4.5, 15)
        scene.background = new THREE.Color('#808080');
        scene.add(light)

        const light2 = new THREE.PointLight()
        light2.position.set(0, 0, 0)
        scene.add(light2)

        const light3 = new THREE.PointLight()
        light3.position.set(15, 4.5, 2.5)
        scene.add(light3)

        const camera = new THREE.PerspectiveCamera(
            75,
            element.offsetWidth / element.offsetHeight,
            0.1,
            1000
        )
        camera.position.z = 2
        camera.position.set(0, (parseInt(Store.storeHeight) * 2 * 100), 0);

        const renderer = new THREE.WebGLRenderer()
        renderer.setSize(element.offsetWidth, element.offsetHeight)
        renderer.setViewport(0, 0, element.offsetWidth, element.offsetHeight)


        const controls = new OrbitControls(camera, renderer.domElement)
        const group = new THREE.Group();
        // let material = [
        //     new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/cooler_inner.png", 1, 1), color: 0x00ff00 }),
        //     new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/cooler_inner.png", 1, 1), color: 0x00ff00 }),
        //     new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/cooler_inner.png", 3, 1), color: 0x00ff00 }),
        //     new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/cooler_inner.png", 3, 1), color: 0x00ff00 }),
        //     new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/cooler_inner.png", 1, 1), color: 0x00ff00 }),
        //     new THREE.MeshBasicMaterial({ wireframe: false, transparent: true, map: loadTexture("model/cooler_inner.png", 1, 1), color: 0x00ff00 }),
        // ];
        // let geometry = new BoxGeometry(10, 10, 10);
        // let mesh = new THREE.Mesh(geometry, material)
        // const storeWidthPercent = 650 / 100
        // const storeWidth = 650
        // const storeLengthPercentage =( parseFloat(Store.storeLength) * 100 ) / storeWidthPercent
        // const storeLength = storeLengthPercentage * 650
        // mesh.position.set(-getDynamicValue(storeWidth/4, 2), -getDynamicValue(parseFloat(Store.storeHeight) * 100, 0.29), -getDynamicValue(storeLength/4, 2));
        // group.add(mesh)

        // let geometry1 = new BoxGeometry(10, 10, 10);
        // let mesh1 = new THREE.Mesh(geometry1, material)

        // mesh1.position.set(getDynamicValue(storeWidth/4, 2), -getDynamicValue(parseFloat(Store.storeHeight) * 100, 0.29), getDynamicValue(storeLength/4, 2));
        // group.add(mesh1)

        Store.rack.forEach((Rack, i) => {
            switch(Rack.type) {
                case 1:
                    group.add(loadRackGroup(Rack, true, i));
                    break;
                case 2:
                    group.add(loadCoolerGroup(Rack, true, i));
                    break;
                case 3:
                    group.add(loadFruitRackGroup(Rack, true, i));
                    break;
                case -1:
                    group.add(loadStoreCornerGroup(Rack));
                    break;
                default:
                    break;
            }
        })

        getCenterModel(group);
        const storeGroup = new THREE.Group();

        storeGroup.add(loadStoreView(Store))
        scene.add(group)
        scene.add(storeGroup);
        scene.rotateY(degToRad(180));

        element.innerHTML = ''
        element.appendChild(renderer.domElement)
        element.addEventListener('resize', onWindowResize, false)
        function onWindowResize() {
            camera.aspect = element.offsetWidth / element.offsetHeight
            camera.updateProjectionMatrix()
            renderer.setSize(element.offsetWidth, element.offsetHeight)
            render()
        }

        function animate() {
            requestAnimationFrame(animate)
            controls.update()
            render()
        }

        function render() {
            renderer.render(scene, camera)
            renderer.setClearColor(0xffffff, 0);
        }
        animate()
    }

    render() {
        return (<></>)
    }
}

export default withRouter(PlanogramStore)