import Actions from '../action'
const initialState = {
    isLoading: false,
    token: undefined,
    user: undefined,
    error: false
}
export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.LOGIN_USER: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.LOGIN_USER_SUCCESS: {

            return {
                ...state,
                isLoading: false,
                token: action.data,
                user: action.data.user,
                error: false
            };
        }
        case Actions.LOGIN_USER_FAILURE: {
            return {
                ...state,
                isLoading: false,
                token: undefined,
                user: undefined,
                error: action.error,
            };
        }
        case Actions.LOGOUT: {
            return {
                ...initialState
            }
        }
        default:
            return state;
    }
};
