import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from '../action';
export function* loginUser() {
  try {
    yield put(Actions.loginUserSuccess({}))
  } catch (error) {
    yield put(Actions.loginUserFailure({}))
  }
}


export default function* loginWatcher() {
  yield all([
    takeLatest(Actions.LOGIN_USER, loginUser),
  ]);
}
