import * as THREE from 'three'
import { BoxGeometry } from 'three';
import { getCenterModel, getContainerHeight, getDynamicValue, rackDepth, staticWidth } from './util';

export const loadBackgroundView = (Rack) => {
    let material = new THREE.MeshBasicMaterial({ wireframe: false, transparent: true });
    let geometry = new BoxGeometry(getDynamicValue(staticWidth, Rack.width), getContainerHeight(Rack.height, Rack.width, true, Rack), getDynamicValue(rackDepth, Rack.width));
    let mesh1 = new THREE.Mesh(geometry, material)
    let { model } = getCenterModel(mesh1)
    mesh1.position.set(0, 0, 0);
    return [model]
}

export const loadStoreCornerModel = (Rack) => {
    const backgroundModel = loadBackgroundView(Rack);
    return [...backgroundModel]
}

export const loadStoreCornerGroup = (Rack) => {
    const cornerModel = loadStoreCornerModel(Rack);
    const group = new THREE.Group();
    cornerModel.forEach((model) => {
        group.add(model);
    })
    group.position.set(getDynamicValue(((parseFloat(Rack.xAxis) + (parseFloat(Rack.depth) / 2)) / 2), 2), -(getDynamicValue((getContainerHeight(Rack.height, Rack.width, true, Rack) / 2), 2)), getDynamicValue(((parseFloat(Rack.yAxis) + (parseFloat(Rack.width) / 2)) / 2), 2))
    getCenterModel(group);
    
    return group;
}
