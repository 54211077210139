import React from 'react';
// import Rack from '../../config/rack.json'
import Rack from '../../config/cooler.json'


export class Home extends React.Component {

    componentDidMount() {
        setTimeout(() => {
            document.getElementById('iframe_rack').contentWindow.postMessage({message: Rack}, "*")
        }, 2000)
    }
    render() {
        return (<div className='rack-canvas'>
            <iframe id="iframe_rack" src="/frame" style={{width: '100%', height: '100%', boxShadow: 'rgb(0 0 0 / 16%) 0px 1px 4px'}}></iframe>
        </div>)
    }
}