import Actions from '../action'
const initialState = {
    isLoading: false,
    data: undefined,
    error: false
}
export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.UPDATE_RACK_DETAIL: {
            return {
                ...state,
                data: action.data,
            };
        }
        default:
            return state;
    }
};
