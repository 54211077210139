
const NAME = "USER";
export const LOGIN_USER = `${NAME}/LOGIN_USER`;
export const LOGIN_USER_SUCCESS = `${NAME}/LOGIN_USER_SUCCESS`;
export const LOGIN_USER_FAILURE = `${NAME}/LOGIN_USER_FAILURE`;

export const LOGOUT = `${NAME}/LOGOUT`;

export const getUserDetail = store => store[NAME].user
export const loginUser = (loginParams, callback) => {
    return {
        type: LOGIN_USER,
        loginParams,
        callback
    }
}

export const loginUserSuccess = (data) => {
    return {
        type: LOGIN_USER_SUCCESS,
        data
    }
}

export const loginUserFailure = (error) => {
    return {
        type: LOGIN_USER_FAILURE,
        error
    }
}


export const logout = () => {
    return {
        type: LOGOUT,
    }
}
