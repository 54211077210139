import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Actions from '../../redux/action';
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

import { loadRackModel } from '../../util/rack';

class Planogram extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        if(this.props.Rack) {
            this.render3DModel()
        }
    }

    componentDidUpdate(nextProps, nextState) {
        if(this.props.Rack !== nextProps.Rack) {
            this.render3DModel()
        }
    }

    render3DModel = () => {
        this.renderRackModel()
    }

    renderRackModel = () => {
        const { Rack } = this.props;
        
        const element = document.getElementById('rack-canvas')
        const scene = new THREE.Scene()
        const light = new THREE.PointLight()
        light.position.set(2.5, 4.5, 15)
        scene.background = new THREE.Color('#808080');

        scene.add(light)

        const light2 = new THREE.PointLight()
        light2.position.set(0, 0, 0)
        scene.add(light2)

        const light3 = new THREE.PointLight()
        light3.position.set(15, 4.5, 2.5)
        scene.add(light3)

        const camera = new THREE.PerspectiveCamera(
            75,
            element.offsetWidth / element.offsetHeight,
            0.1,
            1000
        )
        camera.position.z = parseFloat(Rack.width) * 0.75
        
        const renderer = new THREE.WebGLRenderer()
        renderer.setSize(element.offsetWidth, element.offsetHeight)
        renderer.setViewport(0, 0, element.offsetWidth, element.offsetHeight)
        

        const controls = new OrbitControls(camera, renderer.domElement)


        const rackModel = loadRackModel(Rack);
        rackModel.forEach((model) => {
            scene.add(model)
        })

        element.innerHTML = ''
        element.appendChild(renderer.domElement)
        element.addEventListener('resize', onWindowResize, false)
        function onWindowResize() {
            camera.aspect = element.offsetWidth / element.offsetHeight
            camera.updateProjectionMatrix()
            renderer.setSize(element.offsetWidth, element.offsetHeight)
            render()
        }

        function animate() {
            requestAnimationFrame(animate)
            controls.update()
            render()
        }

        function render() {
            renderer.render(scene, camera)
            renderer.setClearColor(0xffffff, 0);
        }
        animate()
    }

    render() {
        return (<></>)
    }
}

const mapStateToProps = (state) => {
    return {
        store: Actions.getStoreDetail(state)
    }
}

export default withRouter(connect(mapStateToProps, {
    updateRackDetail: Actions.updateRackDetail
})(Planogram))